// Returns valid url string, e.g. '/TEST_MERCHANT/details'
/**
 * Function to get the link URL
 * @param {Object} param0
 * @param {string} param0.configUrl - The config url
 * @param {string} param0.merchantId - The merchant id
 * @param {boolean} param0.isAdminReviewMode - The admin review mode
 * @param {string} param0.adminModeMerchantId - The admin mode merchant id
 * @param {boolean} param0.isAdmin - The is admin
 * @returns {string} The link URL
 * @example
 * getLinkUrl({
 *  configUrl: '/TEST_MERCHANT/details',
 *  merchantId: 'TEST_MERCHANT',
 *  isAdminReviewMode: false,
 *  adminModeMerchantId: 'TEST_MERCHANT',
 *  isAdmin: false,
 * });
 */
const getLinkUrl = ({
  configUrl, merchantId, isAdminReviewMode, adminModeMerchantId, isAdmin,
}) => {
  if (isAdmin) {
    return configUrl;
  }
  if (isAdminReviewMode) {
    return `/merchants/${adminModeMerchantId}${configUrl}`;
  }
  return merchantId ? `/${merchantId}${configUrl}` : configUrl;
};

export default getLinkUrl;
