const filterFunctionForPages = (
  condition,
  processingEnabled,
  isAdminReviewMode,
  isIntegrated,
  portalActive,
  canCreateTestOrders,
  canEnableInStoreReturns,
  hasRefundsEnabled,
) => {
  switch (condition) {
    case 'processingEnabled':
      return processingEnabled;
    case 'hiddenInReviewMode':
      return !isAdminReviewMode;
    case 'isIntegrated':
      return isIntegrated;
    case 'portalActive':
      return portalActive;
    case 'canCreateTestOrders':
      return canCreateTestOrders;
    case 'canEnableInStoreReturns':
      return canEnableInStoreReturns;
    case 'hasRefundsEnabled':
      return hasRefundsEnabled;
    default:
      return true;
  }
};

const filterSectionsPages = ({
  sections,
  processingEnabled,
  isAdminReviewMode,
  isIntegrated,
  portalActive,
  canCreateTestOrders,
  canEnableInStoreReturns,
  hasRefundsEnabled,
}) => {
  const filteredSections = sections?.map((section) => {
    if (section.withFilters) {
      const pagesWithModifiedSubItems = section.pages.map((page) => {
        if (page.subItems) {
          const filteredSubItems = page.subItems.filter((subItem) => filterFunctionForPages(
            subItem.condition,
            processingEnabled,
            isAdminReviewMode,
            isIntegrated,
            portalActive,
            canCreateTestOrders,
            canEnableInStoreReturns,
            hasRefundsEnabled,
          ));
          return {
            ...page,
            subItems: filteredSubItems,
          };
        }
        return page;
      });

      const filteredSection = {
        ...section,
        pages: pagesWithModifiedSubItems.filter((page) => filterFunctionForPages(
          page.condition,
          processingEnabled,
          isAdminReviewMode,
          isIntegrated,
          portalActive,
          canCreateTestOrders,
          canEnableInStoreReturns,
          hasRefundsEnabled,
        )),
      };
      if (filteredSection.pages.length === 0) {
        return null;
      }

      return filteredSection;
    }
    return section;
  });

  return filteredSections;
};

const getNavigationSections = ({
  structure,
  isAdminReviewMode,
  processingEnabled,
  isIntegrated,
  portalActive,
  canCreateTestOrders,
  canEnableInStoreReturns,
  hasRefundsEnabled,
}) => {
  const filteredOptions = {
    topSection: [],
    bottomSection: [],
  };

  // filtering out topSection sections' pages
  const topSectionsWithFilteredPages = filterSectionsPages({
    sections: structure.topSection,
    processingEnabled,
    isAdminReviewMode,
    isIntegrated,
    portalActive,
    canCreateTestOrders,
    canEnableInStoreReturns,
    hasRefundsEnabled,
  });

  // filtering out bottomSection sections' pages
  const bottomSectionsWithFilteredPages = filterSectionsPages({
    sections: structure.bottomSection,
    processingEnabled,
    isAdminReviewMode,
    isIntegrated,
    portalActive,
    canCreateTestOrders,
    canEnableInStoreReturns,
    hasRefundsEnabled,
  });

  filteredOptions.bottomSection = bottomSectionsWithFilteredPages?.filter(Boolean);
  filteredOptions.topSection = topSectionsWithFilteredPages?.filter(Boolean);

  return filteredOptions;
};

export default getNavigationSections;
