import SectionSubpageItem from '@/components/side-navigation/section-subpage-item';
import { useRouter } from 'next/router';
import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { LocaleContext } from '@/contexts/locale-context';
import ChevronDown from '@/public/icons/chevron-down.svg';
import Link from 'next/link';
import getLinkUrl from '@/utilities/getNavigationURL';
import { AdminContext } from '@/contexts/admin-context';

function SectionPageItem({ page }) {
  const router = useRouter();
  const { translate } = useContext(LocaleContext);
  const { adminModeMerchantId, isAdmin, isAdminReviewMode } = useContext(AdminContext);

  const [isOpen, setIsOpen] = useState(() => {
    if (page.subItems) {
      return page.subItems.some((subItem) => router.pathname.includes(subItem.url));
    }
    return false;
  });

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };
  const isActive = router.pathname.includes(page.url);

  const linkURL = useMemo(() => getLinkUrl({
    configUrl: page.url,
    merchantId: router.query.companyId,
    adminModeMerchantId,
    isAdmin: isAdmin && !isAdminReviewMode,
    isAdminReviewMode,
  }), [page.url, router.query.companyId, adminModeMerchantId, isAdmin, isAdminReviewMode]);

  if (page.url) {
    return (
      <>
        <Link
          href={linkURL}
          passHref
        >
          <div className={`page-option ${isActive ? 'active' : ''}`} key={page.title}>
            <a className="body link" href="navigation-href">
              <img className="icon" src={page.icon} alt={translate(page.title)} />
              <span>{translate(page.title)}</span>
            </a>
          </div>
        </Link>
        <style jsx>
          {`
            .page-option {
              display: flex;
              padding: 4px 8px;
              border-radius: 2px;
              justify-content: space-between;
              height: 28px;
              width: 100%;
              cursor: pointer;
            }

            .page-option:hover {
              background: #F9FAFB;
            }

            .page-option .body {
              display: flex;
              align-items: center;
              gap: 8px;
              background: none;
              border: none;
            }

            .page-option .body span {
              font-size: 14px;
              line-height: 18px;
              font-weight: 400;
              text-wrap: nowrap;
            }

            .page-option.active {
              background: #F4F6F8;
            }

            .icon {
              width: 16px;
              height: 16px;
            }
          `}
        </style>
      </>
    );
  }

  return (
    <>
      <button type="button" onClick={handleToggleOpen} className={`page-option expandable ${isActive ? 'active' : ''}`} key={page.title}>
        <div className="body">
          <img src={page.icon} alt={translate(page.title)} />
          <span>{translate(page.title)}</span>
        </div>
        {page.subItems && (
        <div className={`arrow ${isOpen ? 'open' : ''}`}>
          <ChevronDown />
        </div>
        )}
      </button>
      {page.subItems && isOpen && (
      <div className="subpages-container">
        <div className="subpages">
          {page.subItems.map((subItem) => (
            <SectionSubpageItem key={subItem.title} page={subItem} />
          ))}
        </div>
      </div>
      )}
      <style jsx>
        {`
          .page-option {
            display: flex;
            padding: 4px 8px;
            border-radius: 2px;
            justify-content: space-between;
            height: 28px;
            width: 100%;
          }

          .page-option:hover {
            background: #F9FAFB;
          }

          .page-option .body {
            display: flex;
            gap: 8px;
            background: none;
            border: none;
          }

          .expandable {
            background: none;
            border: none;
            cursor: pointer;
          }

          .page-option .body span {
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            text-wrap: nowrap;
          }

          .page-option .arrow {
            display: flex;
            align-items: center;
            transition: transform 0.3s;
          }

          .page-option .arrow.open {
            transform: rotate(180deg);
          }

          .page-option.active {
            background: #F4F6F8;
          }

          .subpages-container {
            padding-left: 16px;
          }

          .subpages {
            padding-left: 8px;
            border-left: 1px solid #E4E4E4;
          }
        `}
      </style>
    </>
  );
}

SectionPageItem.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    icon: PropTypes.string,
    subItems: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
    })),
  }).isRequired,
};

export default SectionPageItem;
