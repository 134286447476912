import { LocaleContext } from '@/contexts/locale-context';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SectionPageItem from '@/components/side-navigation/section-page-item';
/**
 * Component for rendering a section item
 * @param {Object} section - The section object
 * @return {JSX.Element}
 * */

function SectionItem({ section }) {
  const { translate } = useContext(LocaleContext);

  return (
    <div className="section" key={section.title}>
      <span className="title">{translate(section.title)}</span>
      {section.pages.map((page) => (
        <SectionPageItem key={page.title} page={page} />
      ))}
      <style jsx>
        {`
        .section {
            display: flex;
            flex-direction: column;
            gap: 2px;
          }

          .section .title {
            font-size: 11px;
            line-height: 16px;
            font-weight: 500;
            color: #767676;
            padding-left: 8px;
          }

        `}
      </style>
    </div>
  );
}

SectionItem.propTypes = {
  section: PropTypes.shape({
    title: PropTypes.string,
    pages: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
      icon: PropTypes.string,
      subItems: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
      })),
    })),
  }).isRequired,
};

export default SectionItem;
