import React, {
  useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { ChangesContext } from '@/contexts/changes-context';
import { MerchantContext } from '@/contexts/merchant-context';
import { AdminContext } from '@/contexts/admin-context';
import { ThemeContext } from '@/contexts/theme-context';
import { RoutingContext } from '@/contexts/routing-context';
import getEnvVar from '@/utilities/environment-variable';
import PublishButton from '@/components/header-bar/publish-button';
import HeaderBarMenu from './header-bar-menu';

export default function HeaderBar({ logoOnly }) {
  const {
    publishingState, changeStatus,
  } = useContext(ChangesContext);
  const {
    getConfigValue, name: merchantName, contactName: merchantContactName, subBrand,
  } = useContext(MerchantContext);
  const {
    isAdmin, getCarrierLogo, getCarrierAltLogo, getCarrierSubBrandLogoUrl, isAdminReviewMode, subBrandName,
  } = useContext(AdminContext);
  const { primaryColor, logoBackground, headerLogoSize } = useContext(ThemeContext);
  const { showPublishButton, showPublishButtonAdminMode } = useContext(RoutingContext);

  const [logoTimestamp, setLogoTimestamp] = useState('');

  const carrierLogo = () => {
    if (logoBackground) {
      return getCarrierAltLogo();
    }
    if (subBrandName && subBrand === subBrandName && getCarrierSubBrandLogoUrl()) {
      return getCarrierSubBrandLogoUrl();
    }
    return getCarrierLogo();
  };

  const cdnUrl = getEnvVar('REACT_APP_ASSETS_URL');
  const assets = getConfigValue('assets');

  useEffect(() => {
    if (publishingState === changeStatus.NO_CHANGES) {
      setLogoTimestamp(`?ts=${Date.now()}`);
    }
  }, [publishingState]);

  /**
   * Publish button is only shown in mechant mode or in admin review mode,
   * and for specific pages only (driven by config).
   */
  const enablePublishBar = ((isAdminReviewMode || !isAdmin) && showPublishButton()) || showPublishButtonAdminMode();

  const merchantNameType = merchantContactName || merchantName;

  return (
    <div id="header-bar">
      <div className="bar-left">
        <div className="logo-wrapper">
          <div className="carrier-logo-wrapper">
            {carrierLogo() && <img className={`carrier-logo${logoBackground ? ' with-background' : ''}`} alt="" src={carrierLogo()} />}
            {logoBackground && <div className="logo-background" /> }
          </div>
          {(isAdminReviewMode || !isAdmin) && assets?.logoImage && !logoOnly && (
            <img className="company-logo" alt="" src={`${cdnUrl}/${assets?.logoImage}${logoTimestamp}`} />
          )}
        </div>
      </div>
      {!logoOnly && (
      <div className="bar-right">
        { enablePublishBar && (
          <PublishButton />
        )}
        <div className="user-menu">
          <HeaderBarMenu merchantName={isAdmin ? 'A' : merchantNameType} />
        </div>
      </div>
      )}
      <style jsx>
        {`
          #header-bar {
            position: fixed;
            border-bottom: 1px solid var(--color-line-grey);
            height: 70px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            background-color: var(--color-white);
            z-index: var(--zindex-sticky);
          }

          .bar-left {
            display: flex;
            justify-content: flex-start;
          }

          .bar-right {
            display: flex;
            justify-content: flex-end;
            height: 70px;
          }

          .logo-wrapper {
            display: relative;
          }

          .carrier-logo-wrapper {
            display: flex;
            position: absolute;
            background-color: ${logoBackground && primaryColor};
            width: 280px;
            height: 70px;
            z-index: 3;
          }

          .logo-background {
            position: absolute;
            width: 0px;
            height: 0px;
            top: 50%;
            right: -35px;
            transform: translateY(-50%);
            border-top: 70px solid ${primaryColor};
            border-right: 35px solid transparent;
            z-index: 3;
          }

          .carrier-logo {
            position: absolute;
            max-height: ${headerLogoSize.height}px;
            max-width: ${headerLogoSize.width}px;
            top: 50%;
            left: 45px;
            transform: translateY(-50%);
            z-index: 5;
          }

          .carrier-logo.with-background {
            left: 0;
            max-height: unset;
            width: 280px;
          }

          .company-logo {
            position: absolute;
            max-height: ${headerLogoSize.height}px;
            max-width: ${headerLogoSize.width}px;
            object-fit: contain;
            vertical-align: middle;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 5;
          }

          .user-menu {
            margin-top: 12px;
            margin-right: 30px;
          }

          @media (max-width: 1120px) {
            .carrier-logo {
              position: absolute;
              top: 50%;
              left: 20px;
              transform: translateY(-50%) scale(0.9);
           }

            .company-logo {
              position: absolute;
              top: 50%;
              left: 47%;
              transform: translate(-50%, -50%) scale(0.9);
            }
          }
          @media (max-width: 768px) {
            .user-menu {
              margin-right: 10px;
            }
            #header-bar {
              flex-direction: column;
              height: ${logoOnly ? '70' : '135'}px;
              max-width: 100%;
            }

            .bar-left {
              padding-bottom: 30px;
            }

            .carrier-logo,
            .carrier-logo.with-background {
              position: absolute;
              top: ${logoBackground ? '50%' : '70%'};
              left: ${logoBackground ? '47%' : '45px'};
              transform: ${logoBackground ? 'translate(-50%, -50%) scale(0.9)' : 'translate(-20%, -90%) scale(0.8)'};
            }

            .company-logo {
              position: absolute;
              max-height: 60px;
              max-width: 200px;
              top: 50px;
              left: 100%;
              transform: translate(-110%, -80%) scale(0.8);
            }
          }
        `}
      </style>
    </div>
  );
}

HeaderBar.defaultProps = {
  logoOnly: false,
};

HeaderBar.propTypes = {
  logoOnly: PropTypes.bool,
};
